



























import { Component, Vue, Prop } from 'vue-property-decorator';
import { CampaignTableRow } from '@/app/shared/utilities/static-types';
import moment from 'moment';
import { changeCampaignStartDateAction } from '../actions';

@Component({
  name: 'ChangeCampaignStartDatePopup',
})
export default class ChangeCampaignStartDatePopup extends Vue {
  @Prop({ required: true, default: () => ({}) })
  public campaign!: CampaignTableRow;

  public loading = false;
  public show = false;

  public get formattedStartDate() {
    const { START_DATE = new Date() } = this.campaign || {};
    return moment(START_DATE as Date).format('YYYY-MM-DD');
  }

  public set formattedStartDate(val: string) {
    this.campaign.START_DATE = moment(val, 'YYYY-MM-DD').toISOString();
  }

  public open() {
    this.show = true;
  }

  public async updateCampaignStartDate() {
    try {
      this.loading = true;
      await changeCampaignStartDateAction(this.campaign);
      window.location.reload();
    } catch {
      // Handle errors
    } finally {
      this.loading = false;
      this.show = false;
    }
  }
}
